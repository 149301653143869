<messages>["./Registrar"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw0>
    <!-- "registrar does not exist" alert -->
    <v-col cols="12">
      <v-alert
        type="error"
        :value="!isLoading && !registrar">
        {{ $t ('invalidRegistrarId', {id}) }}
      </v-alert>
    </v-col>

    <v-col cols="12">
      <v-card v-if="registrar">
        <v-card-title primary-title>
          <div>
            <div
              v-t="'title.view'"
              class="text-h5"/>
            <div
              v-t="`subtitle.view`"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>

        <v-card-text v-if="!isLoading">
          <name-value-block :items="registrarProperties"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.back'"
            text
            @click="back"/>
          <v-btn
            v-if="isEditButtonVisible"
            v-t="'general.button.edit'"
            color="primary"
            :to="{name: 'registrar.edit', params: {id}}"/>
        </v-card-actions>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions} from 'vuex'
  import BaseLayout from '@/app/core/components/BaseLayout'
  import NameValueBlock from '@/app/core/components/NameValueBlock'

  import {createEmptyRegistrar} from './components/RegistrarCreateUpdate'

  export default {
    name: 'RegistrarView',

    components: {
      BaseLayout,
      NameValueBlock
    },

    props: {
      id: {
        type: [Number, String],
        default: undefined
      }
    },

    data () {
      return {
        isLoading: true,
        isEditButtonVisible: true,
        registrar: undefined
      }
    },

    computed: {
      registrarProperties () {
        const items = []

        const r = this.registrar

        if (r) {
          const props = Object.keys (createEmptyRegistrar ()).filter (it =>
            !['id'].includes (it))

          props.forEach (prop => {
            if (r.hasOwnProperty (prop)) {
              items.push ({
                name: this.$t (`label.${prop}`),
                value: r[prop]
              })
            }
          })
        }

        return items
      }
    },

    watch: {
      id: {
        handler (newValue) {
          this.loadRegistrar (newValue)
        },
        immediate: true
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadRegistrar (id) {
        this.isLoading = true
        this.registrar = undefined

        return this.fetchData ({
          op: 'registrar/load',
          params: {id},
          cb: data => {
            this.registrar = data.registrarData
          },
          cbError: () => {
            this.registrar = null
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>
